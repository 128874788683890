html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.ql-editor {
  min-height: 8em;
}

.dropzone {
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 2px;
  border-color: #d1d5db;
  border-style: dashed;
  margin: 20px 0;
}

.dropzone-inner {
  display: flex;
  color: #4b5563;
  font-size: 0.875rem;
  line-height: 1.25rem;
  justify-content: center;
  align-items: center;
}

.DateRangePicker_picker {
  z-index: 10 !important;
}
